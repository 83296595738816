import WebServiceRequest from "../Api/WebServiceRequest";

class ProductCreate extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("Products/Create");
    }
    setParams(data) {
        super.setFormData(data);
    }
}

class ProductUpdate extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("Products/Update");
    }
    setParams(data) {
        super.setFormData(data);
    }
}

class ProductGetAll extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("Products/GetAllV2");
    }
    setParams(data) {
        super.setFormData(data);
    }
}

class ProductGetBy extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setMethod('GET')
        super.setTag("Products/GetById");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

export {
    ProductCreate,
    ProductUpdate,
    ProductGetAll,
    ProductGetBy
}